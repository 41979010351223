import routes from '../../router/api';
import { objectToQueryString, sendGetRequest, sendPostRequest, sendPxxxRequest } from './Hydra';

export async function configure(purchaseId, body, locale = 'fr', XSfUserId = null) {
  const uri = new URL(routes.fieldRentalPurchase.configure(purchaseId), window.location.origin);

  const response = await sendPostRequest(uri, body, 'PUT', {
    Accept: 'application/ld+json',
    'Accept-Language': locale,
    ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
  });

  return response;
}

export async function edit(purchaseId, body, locale = 'fr', XSfUserId = null) {
  const uri = new URL(routes.fieldRentalPurchase.put(purchaseId), window.location.origin);

  const response = await sendPxxxRequest(uri, body, 'PUT', {
    Accept: 'application/ld+json',
    'Accept-Language': locale,
    ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
  });

  return response;
}

export async function initiateOnlinePayment(
  purchaseType,
  purchaseId,
  body,
  locale = 'fr',
  XSfUserId = null
) {
  let route = routes.fieldRentalPurchase.initiateOnlinePayment(purchaseId);
  console.log('route', route, purchaseType);
  switch (purchaseType) {
    case 'fieldrental':
    case 'field_rental':
      route = routes.fieldRentalPurchase.initiateOnlinePayment(purchaseId);
      break;
    case 'entrance':
      route = routes.entrance.initiateOnlinePayment(purchaseId);
      break;
    case 'virtualcredit':
      route = routes.virtualCreditPurchases.initiateOnlinePayment(purchaseId);
      break;
    case 'simpleproduct':
    case 'simple_product':
      route = routes.simpleProductPurchase.initiateOnlinePayment(purchaseId);
      break;
    default:
      throw new Error('Dont know what to do');
  }

  const uri = new URL(route, window.location.origin);

  const response = await sendPostRequest(uri, body, {
    Accept: 'application/ld+json',
    'Accept-Language': locale,
    ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
  });

  return response;
}

export async function inviteToPurchase(
  purchaseId,
  contacts = null,
  attributions = null,
  locale = 'fr',
  XSfUserId = null
) {
  const uri = new URL(routes.fieldRentalPurchase.invite(purchaseId), window.location.origin);

  const body = {
    ...(contacts ? { contacts } : {}),
    ...(attributions ? { attributions } : {}),
  };

  const response = await sendPostRequest(uri, body, {
    Accept: 'application/ld+json',
    'Accept-Language': locale,
    ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
  });

  return response;
}

export async function cancelInvitation(purchaseId, attributionId, locale = 'fr', XSfUserId = null) {
  const uri = new URL(
    routes.fieldRentalPurchase.cancelInvitation(purchaseId),
    window.location.origin
  );
  return sendPostRequest(
    uri,
    {
      attribution: { id: attributionId },
    },
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );
}

export async function confirmInvitation(
  purchaseId,
  attributionToReplace = null,
  locale = 'fr',
  XSfUserId = null
) {
  const uri = new URL(
    routes.fieldRentalPurchase.confirmInvitation(purchaseId),
    window.location.origin
  );

  const response = await sendPostRequest(
    uri,
    attributionToReplace ? { attribution_to_replace: attributionToReplace } : {},
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );
  return response;
}

export async function declineInvitation(
  purchaseId,
  attributionId,
  locale = 'fr',
  XSfUserId = null
) {
  const uri = new URL(
    routes.fieldRentalPurchase.declineInvitation(purchaseId),
    window.location.origin
  );

  const response = await sendPostRequest(
    uri,
    { attribution: { id: attributionId } },
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );
  return response;
}

export async function getFieldRentalPurchase(id, locale = 'fr', XSfUserId = null) {
  const params = [['locale', locale]];
  if (XSfUserId) {
    params.push(['X-SF-USER-ID', XSfUserId]);
  }
  const uri = new URL(routes.fieldRentalPurchase.get(id), window.location.origin);
  uri.search = new URLSearchParams(params).toString();

  const response = await sendGetRequest(uri);

  return response;
}

export async function getFieldRentalPurchases(options, XSfUserId = null) {
  const params = objectToQueryString(options);
  if (XSfUserId) {
    params.push(['X-SF-USER-ID', XSfUserId]);
  }
  const uri = new URL(routes.fieldRentalPurchase.getCollection, window.location.origin);
  uri.search = new URLSearchParams(params).toString();

  const response = await sendGetRequest(uri);

  return response;
}

export async function getMatches(options, XSfUserId = null) {
  const params = objectToQueryString(options);
  if (XSfUserId) {
    params.push(['X-SF-USER-ID', XSfUserId]);
  }
  const uri = new URL(routes.fieldRentalPurchase.matches, window.location.origin);
  uri.search = new URLSearchParams(params).toString();

  const response = await sendGetRequest(uri);

  return response;
}

// Create
export async function createFieldRentalPurchase(body, locale = 'fr', XSfUserId = null) {
  const uri = new URL(routes.fieldRentalPurchase.post, window.location.origin);

  const response = await sendPostRequest(uri, body, {
    Accept: 'application/ld+json',
    'Accept-Language': locale,
    ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
  });

  return response;
}

export async function cancelParticipation(
  purchaseId,
  attributionId,
  locale = 'fr',
  XSfUserId = null
) {
  const uri = new URL(
    routes.fieldRentalPurchase.cancelParticipation(purchaseId),
    window.location.origin
  );

  const response = await sendPostRequest(
    uri,
    {
      attribution: { id: attributionId },
    },
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );

  return response;
}

export async function cancelReplacement(
  purchaseId,
  attributionId,
  locale = 'fr',
  XSfUserId = null
) {
  const uri = new URL(
    routes.fieldRentalPurchase.cancelReplacement(purchaseId),
    window.location.origin
  );

  const response = await sendPostRequest(
    uri,
    {
      attribution: { id: attributionId },
    },
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );

  return response;
}

export async function cancelBooking(purchaseId, locale = 'fr', XSfUserId = null) {
  const uri = new URL(routes.fieldRentalPurchase.cancelBooking(purchaseId), window.location.origin);

  const response = await sendPostRequest(
    uri,
    {},
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );

  return response;
}

export async function refuseInvitation(purchaseId, attributionId, locale = 'fr', XSfUserId = null) {
  const uri = new URL(routes.fieldRentalPurchase.refuse(purchaseId), window.location.origin);
  return sendPostRequest(
    uri,
    {
      attribution: { id: attributionId },
    },
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );
}

export async function request(purchaseId, attributionId, locale = 'fr', XSfUserId = null) {
  const uri = new URL(routes.fieldRentalPurchase.request(purchaseId), window.location.origin);
  return sendPostRequest(
    uri,
    {},
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );
}

export async function approveParticipationRequest(
  purchaseId,
  attributionId,
  locale = 'fr',
  XSfUserId = null
) {
  const uri = new URL(
    routes.fieldRentalPurchase.acceptInvitation(purchaseId),
    window.location.origin
  );
  return sendPostRequest(
    uri,
    {
      attribution: { id: attributionId },
    },
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );
}

export async function denyParticipationRequest(
  purchaseId,
  attributionId,
  locale = 'fr',
  XSfUserId = null
) {
  const uri = new URL(routes.fieldRentalPurchase.refuse(purchaseId), window.location.origin);
  return sendPostRequest(
    uri,
    {
      attribution: { id: attributionId },
    },
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );
}

export async function payWithPrepaidCard(
  purchaseId,
  attributions,
  creditPurchaseId,
  replace = null,
  quantity = 1,
  guestCount = 0,
  locale = 'fr',
  XSfUserId = null
) {
  const uri = new URL(
    routes.fieldRentalPurchase.payWithPrepaidCard(purchaseId),
    window.location.origin
  );
  return sendPostRequest(
    uri,
    {
      attributions,
      ...(replace ? { attributionsToReplace: replace } : {}),
      card: `/api/credit_purchases/${creditPurchaseId}`,
      quantity,
      guest_count: guestCount,
    },
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );
}

export async function payWithWallet(
  purchaseId,
  attributions,
  walletId,
  replace = null,
  quantity = 1,
  guestCount = 0,
  locale = 'fr',
  XSfUserId = null
) {
  const uri = new URL(routes.fieldRentalPurchase.payWithWallet(purchaseId), window.location.origin);
  return sendPostRequest(
    uri,
    {
      attributions,
      ...(replace ? { attributionsToReplace: replace } : {}),
      wallet: { id: walletId },
      quantity,
      guest_count: guestCount,
    },
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );
}

export async function payOnSite(
  purchaseId,
  attributions,
  replace = null,
  guestCount = 0,
  locale = 'fr',
  XSfUserId = null
) {
  const uri = new URL(routes.fieldRentalPurchase.payOnSite(purchaseId), window.location.origin);
  return sendPostRequest(
    uri,
    {
      attributions,
      ...(replace ? { attributionsToReplace: replace } : {}),
      guestCount,
    },
    {
      Accept: 'application/ld+json',
      'Accept-Language': locale,
      ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
    }
  );
}
