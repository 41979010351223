import { sendGetRequest } from './Hydra';
import apiRoutes from '../../router/api';
import { objToQueryString } from './helpers';

export default {
  /**
   * @param {Object} options
   * @return {Promise<ScanResult>}
   */
  async loadPartnerProducts(options) {
    const selectedOptions = {};
    Object.keys(options)
      .filter((k) => options[k])
      .forEach((k) => {
        selectedOptions[k] = options[k];
      });

    if (!Object.hasOwnProperty.call(selectedOptions, 'page') || !selectedOptions.page) {
      selectedOptions.page = 1;
    }

    // Test required values
    if (!selectedOptions.partner) {
      throw new Error('PartnerId required');
    }

    const uri = new URL(apiRoutes.products.fetch, window.location.origin);
    // console.info('loadproducts : selectedOptions', selectedOptions);
    uri.search = objToQueryString(selectedOptions, '');

    return sendGetRequest(uri);
  },
  async loadProduct(id, options = {}) {
    // Test required values
    if (!id) {
      throw new Error('Product id required');
    }

    const query = new URLSearchParams(options);
    const uri = new URL(apiRoutes.products.get(id), window.location.origin);
    uri.search = query;
    return sendGetRequest(uri, query);
  },
  async loadProductConfiguration(productId, options = {}) {
    // Test required values
    if (!productId) {
      throw new Error('Product id required');
    }

    const selectedOptions = {};
    Object.keys(options)
      .filter((k) => options[k])
      .forEach((k) => {
        selectedOptions[k] = options[k];
      });

    if (!Object.hasOwnProperty.call(selectedOptions, 'page') || !selectedOptions.page) {
      selectedOptions.page = 1;
    }

    const uri = new URL(apiRoutes.products.configuration(productId), window.location.origin);
    uri.search = objToQueryString(selectedOptions, '');
    return sendGetRequest(uri, { Accept: 'application/json' });
  },
};
