import routes from '../../router/api';
import { objectToQueryString, sendGetRequest } from './Hydra';

// export async function loadField (id) {}

export async function loadFields(options) {
  const selectedOptions = {};
  Object.keys(options)
    .filter((k) => options[k])
    .forEach((k) => {
      selectedOptions[k] = options[k];
    });

  if (!Object.hasOwnProperty.call(selectedOptions, 'page') || !selectedOptions.page) {
    selectedOptions.page = 1;
  }

  // Test required values
  if (!selectedOptions.partner) {
    throw new Error('PartnerId required');
  }

  const uri = new URL(routes.fields, window.location.origin);
  uri.search = objectToQueryString(selectedOptions, '');

  return sendGetRequest(uri);
}
