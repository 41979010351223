/* eslint-disable no-param-reassign */
import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  setIsBookingsLoading(state, value) {
    state.isBookingsLoading = value;
  },
  setIsSubscriptionsLoading(state, value) {
    state.isSubscriptionsLoading = value;
  },
  setIsCardsLoading(state, value) {
    state.isSubscriptionsLoading = value;
  },
  setError(state, value) {
    state.error = value;
  },
  setBookings(state, value) {
    state.bookings = value;
  },
  setSubscriptions(state, value) {
    state.subscriptions = value;
  },
  setCards(state, value) {
    state.cards = value;
  },
  setIsSimplePurchasesLoading(state, value) {
    state.isSimplePurchasesLoading = value;
  },
  setSimplePurchases(state, value) {
    state.simplePurchases = value;
  },
};
