import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    isBookingsLoading: false,
    bookings: [],
    isSubscriptionsLoading: false,
    subscriptions: [],
    isCardsLoading: false,
    cards: [],
    isSimplePurchasesLoading: false,
    simplePurchases: [],
    error: null,
  },
  actions,
  getters,
  mutations,
};
