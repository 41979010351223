import { actions, mutations } from './constants';
import api from '../../services/api/Booking';
import productApi from '../../services/api/Product';
import sportApi from '../../services/api/Sport';
import { dateRange } from '../../helpers/date';
import dexie from '../dexie';
import { getOverallAvailabilities } from '../../services/api/FieldRental';
import { loadFields } from '../../services/api/Field';

export default {
  [actions.LOAD]: async (context) => {
    try {
      await context.dispatch(actions.LOAD_PRODUCT, context.state.productId);
      await context.dispatch(actions.LOAD_RESOURCES);
      await Promise.allSettled([
        context.dispatch(actions.LOAD_PRODUCT_CONFIGURATION),
        ...context.state.product.sports.map((sId) => context.dispatch(actions.LOAD_SPORT, sId)),
      ]);

      // await context.dispatch(actions.CLEAR_AVAILABILITIES);
    } catch (e) {
      context.commit(mutations.SET_ERROR, e.name);
    }
  },

  [actions.LOAD_SPORT]: async (context, sportId) => {
    const sport = await sportApi.getSport(sportId);
    context.commit(mutations.SET_SPORT, sport);
  },

  [actions.LOAD_RESOURCES]: async (context) => {
    try {
      const resourceFetchOptions = {
        partner: context.rootState.center.partnerId,
        center: context.rootState.center.id,
        ...(context.state.selectedSportId ? { sports: context.state.selectedSportId } : {}),
        ...(context.state.product ? { product: context.state.product.id } : {}),
      };

      const response = await loadFields(resourceFetchOptions);
      const resources = response['hydra:member'];

      context.commit(mutations.SET_RESOURCES, resources);
      context.commit(
        mutations.SET_SHOWN_RESOURCES,
        resources.map((r) => r.id)
      );
    } catch (e) {
      context.commit(mutations.SET_ERROR, e.name);
    }
  },

  [actions.LOAD_PRODUCT]: async (context, id) => {
    try {
      const product = await productApi.loadProduct(id);
      context.commit(mutations.SET_PRODUCT, product);
    } catch (e) {
      context.commit(mutations.SET_ERROR, e.name);
    }
  },

  [actions.FETCH_TOTAL]: async (context, reference) => {
    try {
      context.commit(mutations.SET_IS_LOADING, true);
      const payload = { contacts: context.getters.CONTACTS };
      const response = await api.computeTotal(reference, payload);
      context.commit(mutations.SET_TOTAL, response);
    } catch (e) {
      context.commit(mutations.SET_TOTAL, null);
      context.commit(mutations.SET_ERROR, e.name);
    }
    context.commit(mutations.SET_IS_LOADING, false);
  },

  [actions.CLEAR_AVAILABILITIES]: async () => {
    await dexie.availabilities.clear();
    await dexie.availabilitiesMeta.clear();
  },

  [actions.CLEAR_CALENDAR]: async (context) => {
    await context.dispatch(actions.CLEAR_AVAILABILITIES);
    context.commit(mutations.SET_SPORT, []);
    context.commit(mutations.SET_SELECTED_SPORT_ID, null);
    context.commit(mutations.SET_ERROR, null);
    context.commit(mutations.SET_OVERALL_AVAILABILITIES, []);
  },

  [actions.CLEAN_AVAILABILITIES]: async () => {
    const fiveMinsAgo = new Date().subtractMinutes(5);
    const oneYearAgo = new Date().subtractDays(365);

    const outdatedQuery = await dexie.availabilitiesMeta
      .where('datefetched')
      .between(oneYearAgo, fiveMinsAgo);

    const outdated = await outdatedQuery.primaryKeys();
    const outdatedDates = outdated.map((o) => o[0]);

    // console.debug('Deleting %s outdated availabilities', outdatedDates.length);
    // console.dir(outdated);

    await dexie.availabilities.where('date').anyOf(outdatedDates).delete();
    await outdatedQuery.delete();
  },

  [actions.GET_EXPIRED_AVAILABILITIES]: async (context, range) => {
    const dates = dateRange(range[0], range[1]);
    const formattedDateRange = dates.map((d) => d.format('YYYY-MM-DD'));

    // Cache duration
    const fiveMinsAgo = new Date().subtractMinutes(5);

    // console.debug('Getting expired availabilities with datefetched between %s and %s', fiveMinsAgo, now);

    const checkDateIsPresentAndFresh = async (d, product) => {
      const response = await dexie.availabilitiesMeta
        .where('date')
        .equals(d)
        .and((a) => a.product === product && a.datefetched > fiveMinsAgo);
      const count = await response.count();
      // console.debug('Fresh data for %s : %d', d, count);
      return count > 0;
    };

    // Filter out dates fetched less than x time ago
    const rawFreshDates = await Promise.allSettled(
      formattedDateRange.map(async (d) =>
        (await checkDateIsPresentAndFresh(d, context.state['calendar/productId']))
          ? Promise.resolve(d)
          : undefined
      )
    );
    // console.debug(rawFreshDates);
    const freshDates = rawFreshDates.filter((v) => v.value).map((v) => v.value);

    return formattedDateRange.filter((d) => !freshDates.includes(d));
  },

  [actions.LOAD_AVAILABILITIES]: async (context, range) => {
    // console.debug(range);
    try {
      const datesToFetch = await context.dispatch(actions.GET_EXPIRED_AVAILABILITIES, range);
      // console.debug(datesToFetch);
      if (datesToFetch && datesToFetch.length) {
        // console.debug('Updating availabilities for dates : ', datesToFetch);
        const result = await Promise.allSettled(
          datesToFetch.map((date) =>
            api.getAvailabilities(context.state.productId, context.rootState['center/id'], {
              date,
              sport: context.state.selectedSportId,
              // fields: context.state.selectedResources,
            })
          )
        );

        // The whole week in a single call
        // const parsedResults = await api.getAvailabilities(context.state.productId, context.rootState['center/id'], {
        //   sport: context.state.selectedSportId,
        //   from: range[0].format('YYYY-MM-DD'),
        //   to: range[1].format('YYYY-MM-DD'),
        //   // fields: context.state.selectedResources,
        // });

        // console.dir(parsedResults);

        // console.dir(result);
        const parsedResults = result.reduce(
          (previousValue, currentValue) => previousValue.concat(currentValue.value),
          []
        );

        // console.dir(parsedResults);

        await context.dispatch(actions.SET_AVAILABILITIES, {
          dates: datesToFetch,
          result: parsedResults,
          product: context.state.productId,
        });
      }
    } catch (e) {
      console.error(e);
      context.commit(mutations.SET_ERROR, e.name);
    }
  },

  [actions.LOAD_OVERALL_AVAILABILITIES]: async (context, range) => {
    try {
      const response = await getOverallAvailabilities(context.state.productId, {
        from: range[0].format('YYYY-MM-DD'),
        to: range[1].format('YYYY-MM-DD'),
        sport: context.state.selectedSportId,
        ...(context.state.selectedResources ? { resources: context.state.selectedResources } : {}),
      });
      // await context.dispatch(actions.SET_OVERALL_AVAILABILITIES, response);
      await context.commit(mutations.SET_OVERALL_AVAILABILITIES, response);
      await context.commit(mutations.SET_OVERALL_AVAILABILITIES_FETCHED_RANGE_FROM, range[0]);
      await context.commit(mutations.SET_OVERALL_AVAILABILITIES_FETCHED_RANGE_TO, range[1]);
      // }
    } catch (e) {
      console.error(e);
      context.commit(mutations.SET_ERROR, e.name);
    }
  },

  [actions.SET_AVAILABILITIES]: async (context, { dates, result, product }) => {
    const now = new Date();
    // console.debug('storing availabilities and metadata in dexie');
    // console.dir(result);
    // First remove existing
    await dexie.availabilities.where('date').anyOf(dates).delete();
    await dexie.availabilitiesMeta.where('date').anyOf(dates).delete();
    // Then insert new
    await dexie.availabilities.bulkAdd(result);
    await dexie.availabilitiesMeta.bulkAdd(
      dates.map((processedDate) => ({ date: processedDate, datefetched: now, product }))
    );
  },
  [actions.LOAD_PRODUCT_CONFIGURATION]: async (context) => {
    try {
      const response = await productApi.loadProductConfiguration(context.state.productId, {
        sport: context.state.selectedSportId,
        resources: context.state.selectedResources,
      });

      context.commit(mutations.SET_PRODUCT_CONFIGURATION, response);
    } catch (e) {
      console.error(e);
    }
  },
};
