import { getField } from 'vuex-map-fields';
import { prefixO } from '../../helpers/date';

export default {
  getField,
  sport(state) {
    if (state.product && state.purchase) {
      const sport = state.product.sports.find((pr) => pr['@id'] === state.purchase.sport);
      return sport ? sport.internal_name : undefined;
    }
    return undefined;
  },
  purchaseActivityDate(state) {
    /** @var {Date|null} */
    let df = state.purchase?.date_from;
    if (typeof df === 'string') {
      df = new Date(df);
    }
    return df
      ? `${prefixO(df.getDate())}/${prefixO(df.getUTCMonth() + 1)}/${df.getFullYear()}`
      : null;
  },
  purchaseActivityStartFormatted(state) {
    /** @var {Date|null} */
    let df = state.purchase?.date_from;
    if (typeof df === 'string') {
      df = new Date(df);
    }
    return df ? `${prefixO(df.getHours())}:${prefixO(df.getMinutes())}` : null;
  },

  purchaseActivityEndFormatted(state) {
    /** @var {Date|null} */
    let dt = state.purchase?.date_to;
    if (typeof dt === 'string') {
      dt = new Date(dt);
    }
    return dt ? `${prefixO(dt.getHours())}:${prefixO(dt.getMinutes())}` : null;
  },
  isPurchasePast(state) {
    return !!state.purchase?.date_to && state.purchase?.date_to < new Date();
  },
  isPurchasePublic(state) {
    return state.purchase?.visibility === 'public';
  },
  isPurchaseCanceled(state) {
    return state.purchase?.status === 'canceled';
  },
  isPurchaseComplete(state) {
    return state.purchase?.status === 'confirmed';
  },
  isSplitPaymentEnabled(state) {
    return !!state.configuration?.split_payment_enabled;
  },
  isConfigurePlayersEnabled(state) {
    return state.configuration.configure_players;
  },
  hasAPlayerThatWantsToBeReplaced(state, getters) {
    return getters.replacementRequests.length > 0;
  },
  replacementRequests(state) {
    return state.purchase?.attributions.filter((a) => a.status === 'to_replace');
  },
  participationRequests(state) {
    return state.purchase?.attributions.filter((a) => a.status === 'requested');
  },
  canAddPlayers(state, getters) {
    const { actions } = state.purchase;
    return (
      !getters.isPurchaseCanceled &&
      !getters.isPurchasePast &&
      getters.isConfigurePlayersEnabled &&
      actions?.includes('invite')
    );
  },
  isAmountInconclusive(state) {
    return state.purchase && state.purchase.player_count
      ? Number.parseFloat(state.purchase.amount) !==
          state.purchase.price_per_person * state.purchase.player_count
      : false;
  },
  isDepositPaid(state) {
    return state.purchase.deposit?.enabled && state.purchase.deposit?.remaining_amount <= 0;
  },
  canPayInFull(state, getters) {
    return (
      !getters.isMyAttributionTheOnlyThingToPay &&
      (!getters.myAttribution ||
        // There is a remaining amount
        state.purchase.remaining_amount > 0 ||
        // There are many unpaid attributions
        getters.unpaidAttributions.length > 1 ||
        // There are some attributions left to pay, but mine is already paid
        (getters.unpaidAttributions.length > 0 && getters.myAttribution.payment_status === 'paid'))
      // || state.purchase.payment_status !== 'paid';
    );
  },
  canPayDeposit(state) {
    return (
      state.purchase.deposit?.enabled &&
      state.purchase.deposit?.remaining_amount > 0 &&
      state.purchase.actions?.includes('pay')
    );
  },
  canPayMyShare(state, getters) {
    return (
      getters.isSplitPaymentEnabled &&
      !!getters.myAttribution &&
      getters.myAttribution.payment_status !== 'paid'
    );
  },
  canPaySomeShares(state, getters) {
    return (
      getters.isSplitPaymentEnabled &&
      !getters.isMyAttributionTheOnlyThingToPay &&
      (state.purchase.remaining_amount > 0 || getters.hasAPlayerThatWantsToBeReplaced)
    );
  },
  // We will invite up to the max player count
  guestCountToPayInFull(state, getters) {
    const countAttributionsInSight =
      getters.unpaidAttributions.length + getters.paidAttributions.length;
    return (
      state.purchase.player_count - Math.min(countAttributionsInSight, state.purchase.player_count)
    );
  },
  guestCountToPayDeposit(state, getters) {
    return Math.max(
      0,
      (state.purchase.deposit?.minimum_shares ?? 0) -
        getters.unpaidAttributions.length -
        getters.paidAttributions.length
    );
  },
  prepaidCardOptions(state) {
    return (
      state.paymentMethods &&
      state.paymentMethods.filter(
        (p) => p.type === 'credit' && ['confirmed', 'added', 'paid'].includes(p.status)
      )
    );
  },
  walletOptions(state) {
    return state.paymentMethods && state.paymentMethods.filter((p) => p.type === 'wallet');
  },
  hasWalletOption(state, getters) {
    return state.paymentMethods && getters.walletOptions.length > 0;
  },
  hasBancontactOption(state) {
    return (
      state.paymentMethods && state.paymentMethods.findIndex((m) => m.type === 'bancontact') !== -1
    );
  },
  hasCreditCardOption(state) {
    return (
      state.paymentMethods && state.paymentMethods.findIndex((m) => m.type === 'creditcard') !== -1
    );
  },
  hasCardPaymentOption(state, getters) {
    return !!getters.hasBancontactOption || !!getters.hasCreditCardOption;
  },
  hasPrepaidCardOption(state, getters) {
    return (getters.prepaidCardOptions?.length ?? 0) > 0;
  },
  hasOnsitePaymentOption(state) {
    return (
      state.paymentMethods && state.paymentMethods.findIndex((m) => m.type === 'onsite') !== -1
    );
  },
  hasSpecialPaymentMethods(state, getters) {
    return (
      getters.hasPrepaidCardOption ||
      // I don't know why the call to the getter isn't working, and at this point fuck it
      state.paymentMethods.findIndex((m) => m.type === 'onsite') !== -1 ||
      getters.hasWalletOption
    );
  },
  canPayWithQuota(state, getters) {
    return (
      getters.myAttribution &&
      getters.myAttribution.actions &&
      getters.myAttribution.actions.includes('confirm')
    );
  },
  iAmInvited(state, getters) {
    // So there are a couple Products that do not support the concept of invitation
    // but can still inherit some related statuses because of weakness in the related
    // code.
    const invitationSupportedProducts = ['FieldRentalPurchase'];
    return (
      invitationSupportedProducts.includes(state.purchase['@type']) &&
      !!getters.myAttribution &&
      ['invited', 'declined', 'accepted_by_organizer'].includes(getters.myAttribution.status)
    );
  },
  iAmTheBuyer(state, getters) {
    const myAttributionIsBuyerOne = !!getters.myAttribution?.buyer;
    const purchaseBuyerIsMe =
      state.purchase?.buyer?.id && getters.myUserId === state.purchase.buyer.id;
    return myAttributionIsBuyerOne || purchaseBuyerIsMe;
  },
  myUserId(state, getters, rootState) {
    return rootState.user?.user?.id;
  },
  myContactId(state, getters, rootState) {
    return rootState.user?.user?.contact?.id;
  },
  myAttribution(state, getters) {
    return getters.myContactId
      ? state.purchase?.attributions.find(
          (attr) => !!attr.contact && attr.contact.id === getters.myContactId
        )
      : null;
    // if (!!attr.contact && attr.contact.id === getters.myContactId) { console.log(attr); }
  },
  isPhoneRequiredAndMissing(state, getters, rootState) {
    return (
      !rootState.user.user?.contact.has_phone_number && state.configuration?.booking_phone_required
    );
  },
  canEditSettings(state, getters) {
    // Either the buyer or everyone participating if buyer is gone
    return !!getters.iAmTheBuyer || !!getters.hasBuyerLeft;
  },
  hasBuyerLeft(state, getters) {
    return (
      getters.buyerAttribution &&
      getters.buyerAttribution.status === 'replaced' &&
      getters.myAttribution.status !== 'requested'
    );
  },
  canEditPlayers(state, getters) {
    // Center must be ok with editing
    return (
      !!state.purchase &&
      !!state.configuration &&
      !!(
        (
          (state.configuration?.configure_players || state.configuration?.request_player_count) &&
          !getters.isPurchasePast && // purchase should not be in the past
          state.purchase.actions &&
          state.purchase.actions.includes('invite') && // Invite in allowed actions
          (getters.iAmTheBuyer ||
            (getters.myAttribution &&
              ['accepted', 'confirmed', 'to_replace'].includes(getters.myAttribution.status)))
        ) // Buyer have access
      )
    );
  },
  canCancelEverything(state, getters) {
    // If everything is paid in full by the buyer or if it is not paid in full
    return (
      !getters.isPurchaseCanceled &&
      !getters.isPurchasePast &&
      getters.canEditSettings &&
      state.purchase.cancellation &&
      state.purchase.cancellation.is_supported &&
      state.purchase.actions &&
      state.purchase.actions?.includes('cancel')
    );
  },
  canCancelForMe(state, getters) {
    return (
      state.purchase.actions &&
      !getters.isPurchaseCanceled &&
      !getters.isPurchaseComplete &&
      getters.myAttribution &&
      Array.isArray(getters.myAttribution.actions) &&
      getters.myAttribution.actions?.includes('cancel') &&
      getters.myAttribution.status !== 'canceled'
    );
  },
  canAskToBeSubstituted(state, getters) {
    // console.dir(getters.myAttribution.actions);
    return (
      state.purchase['@type'] === 'FieldRentalPurchase' &&
      !getters.isPurchaseCanceled &&
      !!getters.isPurchaseComplete &&
      !!getters.myAttribution &&
      Array.isArray(getters.myAttribution.actions) &&
      getters.myAttribution.actions?.includes('request_replacement')
    );
  },
  buyerAttribution(state) {
    return state.purchase?.attributions.find((attr) => attr.buyer);
  },
  unpaidAttributions(state) {
    return (
      state.purchase?.attributions
        // .filter((a) => a.payment_status === 'unpaid' && !['requested', 'refused', 'canceled'].includes(a.status));
        .filter(
          (a) =>
            a.actions?.includes('pay') && !['requested', 'refused', 'canceled'].includes(a.status)
        )
    );
  },
  paidAttributions(state) {
    return state.purchase?.attributions.filter(
      (a) => a.status === 'confirmed' && a.payment_status === 'paid'
    );
  },
};
