import { actions, mutations } from './constants';
import { loadCenter } from '../../services/api/Center';
import { loadFields } from '../../services/api/Field';

export default {
  [actions.FETCH_CENTER]: async (context, id) => {
    try {
      context.commit(mutations.SET_IS_LOADING, true);
      const response = await loadCenter(id);
      context.commit(mutations.SET_ADDRESS, response.address);
      context.commit(mutations.SET_IS_LOADING, false);
    } catch (e) {
      console.error(e);
    }
  },
  [actions.FETCH_OPENING_HOURS]: async (context) => {
    try {
      context.commit(mutations.SET_OPENING_HOURS, []);
    } catch (e) {
      context.commit(mutations.SET_OPENING_HOURS, []);
    }
  },
  load: async (context) => {
    // console.log('centerload', context.state.id);
    context.commit(mutations.SET_IS_LOADING, true);

    await Promise.allSettled([
      context.dispatch(actions.FETCH_CENTER, context.state.id),
      context.dispatch(actions.FETCH_OPENING_HOURS),
      context.dispatch(actions.FETCH_RESOURCES),
    ]);

    context.commit(mutations.SET_IS_LOADING, false);
  },
  [actions.FETCH_RESOURCES]: async (context) => {
    const resourceFetchOptions = {
      partner: context.state.partnerId,
      center: context.state.centerId,
    };

    const response = await loadFields(resourceFetchOptions);
    const resources = response['hydra:member'];

    context.commit(mutations.SET_RESOURCES, resources);
    context.commit(
      mutations.SET_SHOWN_RESOURCES,
      this.resources.map((r) => r.id)
    );
  },
};
