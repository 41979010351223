import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  updateZone(state, zone) {
    state.zone = zone;
  },
  updateZoneName(state, name) {
    state.zoneName = name;
  },
  updateResults(state, results) {
    state.results = results;
  },
  updatePaginationCount(state, value) {
    state.paginator.count = value;
  },
  updatePaginationPageCount(state, value) {
    state.paginator.page_count = value;
  },
  updatePaginationPage(state, value) {
    state.paginator.page = value;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setMapIsLoading(state, value) {
    state.isMapLoading = value;
  },
  setMapResults(state, value) {
    state.mapResults = value;
  },
  setGeocoding(state, value) {
    state.geocoding = value;
  },
  setSport(state, value) {
    state.sport = value;
  },
  resetLocalization(state) {
    state.zone = null;
    state.zoneName = null;
    state.geocoding = null;
  },
  setFilters(state, value) {
    state.filters = value;
  },
  setAttributes(state, value) {
    state.attributes = Object.fromEntries(value.map((v) => [v['@id'], v]));
  },
  setFetchWeek(state, value) {
    state.fetchWeek = value;
  },
  reset(state) {
    // state.predefinedSearch = false;
    state.paginator = {
      count: 0,
      page: 1,
      page_count: 0,
      limit: 15,
    };
    state.results = [];
  },
};
