import routes from '../../router/api';
import { objectToQueryString, sendGetRequest } from './Hydra';

export async function loadAttributes(options = {}, XSfUserId = null) {
  const uri = new URL(routes.attributes, window.location.origin);

  if (!Object.hasOwnProperty.call(options, 'page') || !options.page) {
    // eslint-disable-next-line no-param-reassign
    options.page = 1;
  }

  uri.search = objectToQueryString(options);
  return sendGetRequest(uri, {
    ...(XSfUserId ? { 'X-SF-USER-ID': XSfUserId } : {}),
  });
}
