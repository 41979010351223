export function firstOf(date, view) {
  if (view === 'month') {
    const d = new Date(date);
    return new Date(d.setDate(1));
  }
  if (view === 'week') {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  return date;
}

export function lastOf(date, view, offset = 0) {
  if (view === 'week') {
    const d = new Date(date);
    const day = d.getDay() === 0 ? 7 : d.getDay();
    const diff = d.getDate() + 7 + offset - day; // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  if (view === 'month') {
    const d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return new Date(d.setDate(1));
  }

  return date;
}

export function dateRange(startDate, endDate, steps = 1) {
  const dateArray = [];
  const td = (val) => {
    const d = new Date(val);
    d.setHours(0);
    d.setMinutes(1);
    d.setSeconds(0);
    return d;
  };

  const currentDate = td(startDate);
  while (currentDate <= td(endDate)) {
    dateArray.push(new Date(currentDate));
    // Use UTC date to prevent problems with time zones and DST
    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
  }

  return dateArray;
}

/**
 * @param v {number}
 * @returns {string|*}
 */
export function prefixO(v) {
  return v < 10 ? `0${v}` : v;
}

export function getRelativeTime(locale, d1, d2 = new Date()) {
  const units = {
    year: 24 * 60 * 60 * 1000 * 365,
    month: (24 * 60 * 60 * 1000 * 365) / 12,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000,
  };
  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });
  const elapsed = d1 - d2;

  // "Math.abs" accounts for both "past" & "future" scenarios
  const e = Object.keys(units).find((u) => Math.abs(elapsed) > units[u] || u === 'second');

  return e ? rtf.format(Math.round(elapsed / units[e]), e) : null;
}
